import { AsyncPipe, CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
	kebabCase,
	CapitalizeSpecificWordsPipe,
	SrPunctuationPipe,
	DeferLoadDirective,
	JoinPipe,
	ButtonComponent,
	NavigationItemGroupWithAnalytics,
	FlagKey,
	FlagService,
	MegaMenuProductDiscovery,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

interface SmallTileProduct {
	sku: string;
	src: string;
	name: string;
}

@Component({
	selector: 'global-nav-feature-mega-menu-small-tile',
	templateUrl: './feature-mega-menu-small-tile.component.html',
	styleUrls: ['./feature-mega-menu-small-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AsyncPipe,
		CurrencyPipe,
		NgIf,
		RouterLink,
		TitleCasePipe,
		CapitalizeSpecificWordsPipe,
		SrPunctuationPipe,
		DeferLoadDirective,
		JoinPipe,
		ButtonComponent,
	],
})
export class FeatureMegaMenuSmallTileComponent implements OnInit, OnDestroy {
	@Input()
	public aislesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public shelvesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public width: number;
	public isVisible$ = new BehaviorSubject<boolean>(false);
	public product: SmallTileProduct | undefined;
	public linkHref = '';
	private destroyed$: Subject<boolean> = new Subject();

	private products: SmallTileProduct[] = [
		{
			sku: '281677',
			src: '/images/global-nav/mega-menu-recipe-stamp/small-tile-mainland-butter.jpg',
			name: 'mainland butter',
		},
		{
			sku: '297515',
			src: '/images/global-nav/mega-menu-recipe-stamp/small-tile-bluebird-ready-salted.jpg',
			name: 'bluebird originals potato chips ready salted',
		},
	];

	private departments = [
		{ department: 'Pantry', sku: '297515' },
		{ department: 'Fridge & Deli', sku: '281677' },
	];
	private aisles = [
		{ aisle: 'Snacks & Sweets', sku: '297515' },
		{ aisle: 'Eggs, Butter & Spreads', sku: '281677' },
	];
	private megaMenuProductDiscoveryVarient = '';

	constructor(private flagService: FlagService) {}

	ngOnInit(): void {
		this.flagService
			.getVariationKey(FlagKey.megaMenuProductDiscovery)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((varient) => {
				if (
					varient === MegaMenuProductDiscovery.Variation1 ||
					varient === MegaMenuProductDiscovery.Variation2
				) {
					this.megaMenuProductDiscoveryVarient = varient;
					this.showDepartmentProducts();
					this.showAislesProducts();
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.isVisible$.complete();
	}

	get nameInKebabCase(): string {
		return kebabCase(this.product?.name || '');
	}

	private showDepartmentProducts(): void {
		this.aislesNavItems$.pipe(takeUntil(this.destroyed$)).subscribe((aislesNavItems) => {
			if (aislesNavItems && this.megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation1) {
				const department = this.departments.find((d) => d.department === aislesNavItems[0]?.label);
				if (department) {
					this.product = this.products.find((p) => p.sku === department.sku);
					this.linkHref = `/shop/productdetails?stockcode=${this.product?.sku}&name=${this.nameInKebabCase}`;
					this.isVisible$.next(true);
					return;
				}
			}
			this.isVisible$.next(false);
		});
	}

	private showAislesProducts(): void {
		this.shelvesNavItems$.pipe(takeUntil(this.destroyed$)).subscribe((shelvesNavItems) => {
			if (shelvesNavItems && this.megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation2) {
				const aisle = this.aisles.find((d) => d.aisle === shelvesNavItems[0]?.label);
				if (aisle) {
					this.product = this.products.find((p) => p.sku === aisle.sku);
					this.linkHref = `/shop/productdetails?stockcode=${this.product?.sku}&name=${this.nameInKebabCase}`;
					this.isVisible$.next(true);
					return;
				}
			}
			this.isVisible$.next(false);
		});
	}
}
