import { AsyncPipe, CurrencyPipe, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
	kebabCase,
	CapitalizeSpecificWordsPipe,
	SrPunctuationPipe,
	DeferLoadDirective,
	sentenceCase,
	JoinPipe,
	ButtonComponent,
	NavigationItemGroupWithAnalytics,
	FlagKey,
	FlagService,
	MegaMenuProductDiscovery,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ProductResponse, ProductDetailsResponse } from '@woolworthsnz/trader-api';
import { ProductService } from '../../services/product-service';

@Component({
	selector: 'global-nav-feature-mega-menu-long-tile',
	templateUrl: './feature-mega-menu-long-tile.component.html',
	styleUrls: ['./feature-mega-menu-long-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AsyncPipe,
		CurrencyPipe,
		NgIf,
		RouterLink,
		TitleCasePipe,
		CapitalizeSpecificWordsPipe,
		SrPunctuationPipe,
		DeferLoadDirective,
		JoinPipe,
		ButtonComponent,
	],
})
export class FeatureMegaMenuLongTileComponent implements OnInit, OnDestroy {
	@Input()
	public aislesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public shelvesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	@Input()
	public width: number;
	public isVisible$ = new BehaviorSubject<boolean>(false);
	public linkHref = '';
	private destroyed$: Subject<boolean> = new Subject();

	private departments = [
		{ department: 'Pantry', sku: '297515' },
		{ department: 'Fridge & Deli', sku: '281677' },
	];
	private aisles = [
		{ aisle: 'Snacks & Sweets', sku: '297515' },
		{ aisle: 'Eggs, Butter & Spreads', sku: '281677' },
	];
	private products = new Map<string, ProductDetailsResponse>();
	private megaMenuProductDiscoveryVarient = '';

	constructor(
		public productService: ProductService,
		private flagService: FlagService
	) {}

	get product(): ProductDetailsResponse {
		return this.productService.state.product;
	}

	get cupPrice(): string | false {
		return this.productService.cupPrice;
	}

	get isKgItem(): boolean {
		return this.productService.unit === ProductResponse.UnitEnum.Kg;
	}

	get supportsBothEachAndKgPricing(): boolean | undefined {
		return this.productService.supportsBothEachAndKgPricing;
	}

	get isEachKgMeatProduct(): boolean | undefined {
		return this.supportsBothEachAndKgPricing;
	}

	get productTitle(): string | undefined {
		return this.isEachKgMeatProduct && this.product?.size?.volumeSize
			? `${this.product?.name} ${this.product?.size?.volumeSize}`
			: this.product?.name;
	}

	get unit(): ProductResponse.UnitEnum | undefined {
		if (this.productService.supportsBothEachAndKgPricing) {
			return this.productService.selectedPurchasingUnit;
		}

		return this.productService.unit;
	}

	get nameInKebabCase(): string {
		return kebabCase(this.product?.name || '');
	}

	get src(): string {
		return (this.product?.images || [])[0].small || '';
	}

	get volumeSize(): string | false | undefined {
		return (
			this.product?.size?.volumeSize &&
			!this.product.supportsBothEachAndKgPricing &&
			sentenceCase(this.product?.size?.volumeSize)
		);
	}

	get packageType(): string | false | undefined {
		return (
			this.product?.size?.packageType &&
			!this.product.supportsBothEachAndKgPricing &&
			sentenceCase(this.product?.size?.packageType)
		);
	}

	get ariaLabels(): string[] {
		const labels: string[] = [];
		if (this.packageType) {
			labels.push(`Package type ${this.packageType}.`);
		}
		if (this.volumeSize && !this.isKgItem) {
			labels.push(`Volume size ${this.volumeSize}.`);
		}
		if (this.cupPrice) {
			labels.push(`Cup price ${this.cupPrice}.`);
		}
		return labels;
	}

	ngOnInit(): void {
		this.flagService
			.getVariationKey(FlagKey.megaMenuProductDiscovery)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((varient) => {
				if (
					varient === MegaMenuProductDiscovery.Variation3 ||
					varient === MegaMenuProductDiscovery.Variation4
				) {
					this.megaMenuProductDiscoveryVarient = varient;
					this.loadProductDetail();
					this.showDepartmentProducts();
					this.showAislesProducts();
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.isVisible$.complete();
	}

	getImageUrl(type: string): string {
		return `/images/product_${type}.png`;
	}

	private loadProductDetail(): void {
		this.departments.forEach((department) => {
			this.productService
				.getProductDetail(department.sku)
				.pipe(takeUntil(this.destroyed$))
				.subscribe((response: ProductDetailsResponse) => {
					if (response.sku && response.name) {
						this.products.set(response.sku, response);
						this.linkHref = `/shop/productdetails?stockcode=${response?.sku}&name=${kebabCase(response.name)}`;
					}
				});
		});
	}

	private showDepartmentProducts(): void {
		this.aislesNavItems$.pipe(takeUntil(this.destroyed$)).subscribe((aislesNavItems) => {
			if (aislesNavItems && this.megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation3) {
				const department = this.departments.find((d) => d.department === aislesNavItems[0]?.label);
				if (department) {
					this.productService.setState({ product: this.products.get(department.sku) });
					this.isVisible$.next(true);
					return;
				}
			}
			this.isVisible$.next(false);
		});
	}

	private showAislesProducts(): void {
		this.shelvesNavItems$.pipe(takeUntil(this.destroyed$)).subscribe((shelvesNavItems) => {
			if (shelvesNavItems && this.megaMenuProductDiscoveryVarient === MegaMenuProductDiscovery.Variation4) {
				const aisle = this.aisles.find((d) => d.aisle === shelvesNavItems[0]?.label);
				if (aisle) {
					this.productService.setState({ product: this.products.get(aisle.sku) });
					this.isVisible$.next(true);
					return;
				}
			}
			this.isVisible$.next(false);
		});
	}
}
