import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	TrackByFunction,
} from '@angular/core';
import {
	ButtonComponent,
	CutoutModalTargetDirective,
	FeatureService,
	getTrackingData,
	isLinkAbsolute,
	NavigationItemGroupWithAnalytics,
	NavigationItemWithAnalytics,
	NavigationMenuItemWithAnalytics,
	NotificationType,
	PromoBasicArgs,
	PromoTileImpressionArgs,
	SvgIconComponent,
	TrackingData,
	TrackingEvent,
	TrackEventDirective,
	TrackImpressionDirective,
	ImpressionTrackingBehaviourType,
	TRADER_BASE_URL,
	FlagService,
	FlagKey,
	BrowseMenuHoverExperimentVariants,
	FeatureBrandTileExperimentVariants,
	MaybeExternalLinkDirective,
	MegaMenuProductDiscovery,
} from '@woolworthsnz/styleguide';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { BrowseMenuPromoTile, ContextResponse, NavigationMenuItem } from '@woolworthsnz/trader-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { generateAislesNavItems, generateShelvesNavItems } from '../../helpers/browse-nav.helper';
import { NavigationItemMap } from '../../models/browse-nav.model';
import { NavItemsService } from '../../services/nav-items.service';
import {
	tealiumUtagBrowseMenuDepartment,
	tealiumUtagBrowseMenuAisle,
	tealiumUtagBrowseMenuShelf,
	tealiumUtagBrowseMenuPromoTile,
} from '../../constants/tealium-events.constants';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { BrowseMenuItemsComponent } from '../browse-menu-items/browse-menu-items.component';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import EnabledFeaturesEnum = ContextResponse.EnabledFeaturesEnum;
import { FeatureProductStampComponent } from '../feature-product-stamp/feature-product-stamp.component';
import { MenuKeyboardNavigationDirective } from '../../directives';
import { FeatureRecipeStampComponentComponent } from '../feature-recipe-stamp-component/feature-recipe-stamp-component.component';
import { browseNavAisleItemMockWithPromoTiles } from '../../mocks'; // Mock data will be remove when Aisle promotion tile API is ready
import { FeatureMegaMenuLongTileComponent } from '../feature-mega-menu-long-tile/feature-mega-menu-long-tile.component';
import { FeatureMegaMenuSmallTileComponent } from '../feature-mega-menu-small-tile/feature-mega-menu-small-tile.component';

export enum CurrentBrowseLevel {
	Departments,
	Aisles,
	Shelves,
}

type MenuNavigationIndexProp = 'currentDepartmentIndex' | 'currentAisleIndex' | 'currentShelveIndex';

const MENU_NAV_INDEX_MAPPING: { [key in CurrentBrowseLevel]: MenuNavigationIndexProp } = {
	[CurrentBrowseLevel.Departments]: 'currentDepartmentIndex',
	[CurrentBrowseLevel.Aisles]: 'currentAisleIndex',
	[CurrentBrowseLevel.Shelves]: 'currentShelveIndex',
};

const getNextMenuItemIndex = (nextIndex: number, menuItemsLength = 0): number => {
	const theLastMenuItemIndex = menuItemsLength > 0 ? menuItemsLength - 1 : 0;

	if (nextIndex < 0) {
		return theLastMenuItemIndex;
	} else if (nextIndex >= menuItemsLength) {
		return 0;
	} else {
		return nextIndex;
	}
};

@Component({
	selector: 'global-nav-browse-menu',
	templateUrl: './browse-menu.component.html',
	styleUrls: ['./browse-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MaybeExternalLinkDirective,
		NgClass,
		NgIf,
		ButtonComponent,
		SvgIconComponent,
		BrowseMenuItemsComponent,
		NgFor,
		CutoutModalTargetDirective,
		TrackEventDirective,
		RouterLinkActive,
		RouterLink,
		AsyncPipe,
		TrackImpressionDirective,
		FeatureProductStampComponent,
		FeatureRecipeStampComponentComponent,
		MenuKeyboardNavigationDirective,
		FeatureMegaMenuLongTileComponent,
		FeatureMegaMenuSmallTileComponent,
	],
})
export class BrowseMenuComponent implements OnInit, OnDestroy {
	@Input()
	browseNavItems: NavigationItemGroupWithAnalytics[];

	@Input()
	otherNavItems: NavigationItemGroupWithAnalytics[];

	@Input()
	trackingName: string;

	@HostBinding('class.isDesktopNav')
	@Input()
	isDesktopNav = true;

	@Input() showTitle = true;

	@Input() isOpen = false;

	@Output() navItemClicked = new EventEmitter<void>();
	@Output() backButtonClicked = new EventEmitter<void>();
	@Output() closeButtonClicked = new EventEmitter<void>();
	promoImpressionArgs: Map<string, PromoTileImpressionArgs> = new Map();
	promoImpressionTrackingBehaviour: Map<string, ImpressionTrackingBehaviourType> = new Map();

	shouldShowMegaMenuABTestLongTile = false;
	shouldShowMegaMenuABTestSmallTile = false;
	isBrowseMenuAislePromoTileEnabled: boolean;
	currentBrowseLevel = CurrentBrowseLevel;
	currentLevel: CurrentBrowseLevel = CurrentBrowseLevel.Departments;
	currentDepartment: NavigationItemWithAnalytics | undefined;
	currentPromoTiles: BrowseMenuPromoTile[] | undefined;
	currentAisle: NavigationItemWithAnalytics | undefined;
	public isNewBrowseMenuLinksEnabled$: Observable<boolean>;

	public hoverExperimentActive = false;
	public dynamicNavItem$: Observable<NavigationMenuItemWithAnalytics | undefined>;
	public aislesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	public shelvesNavItems$ = new BehaviorSubject<NavigationItemGroupWithAnalytics[] | undefined>(undefined);
	public shouldShowFeatureRecipeStamp$ = new Observable<boolean>();
	private _currentDepartmentIndex = 0;
	private _currentAisleIndex = 0;
	private _currentShelveIndex = 0;
	private trackingEvent = TrackingEvent;
	private notificationType = NotificationType;
	private megaMenuFeatureBrandTileVariationKey: string;
	private cartologyFeatureBrandTile = 'Cartology Feature Brand Tile';
	private aislesNavItems: NavigationItemGroupWithAnalytics[] | undefined;
	private shelveNavItems: NavigationItemGroupWithAnalytics[] | undefined;
	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private cdr: ChangeDetectorRef,
		private featureService: FeatureService,
		private flagService: FlagService,
		private navItemsService: NavItemsService,
		private tealiumUtagService: TealiumUtagService,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl: string
	) {}

	get showTopLevelBackButton(): boolean {
		return !this.isDesktopNav && this.currentLevel === CurrentBrowseLevel.Departments && !this.showTitle;
	}

	get hideDepartmentsInMobile(): boolean {
		return !this.isDesktopNav && this.currentLevel !== CurrentBrowseLevel.Departments;
	}

	get showBackButtonInAislesAndShelves(): boolean {
		return !this.isDesktopNav && this.currentLevel !== CurrentBrowseLevel.Departments;
	}

	get urlOddBunch(): string {
		return `${this.traderBaseUrl ?? ''}/shop/browse/fruit-veg/the-odd-bunch`;
	}

	get url3for20(): string {
		return `${this.traderBaseUrl ?? ''}/shop/browse/meat-poultry/3-for-20`;
	}

	get megaMenuFeatureBrandTileEnabled(): boolean {
		return (
			this.megaMenuFeatureBrandTileVariationKey !== FeatureBrandTileExperimentVariants.Control &&
			this.megaMenuFeatureBrandTileVariationKey !== FeatureBrandTileExperimentVariants.Off
		);
	}

	get shouldShowMegaMenuLongTile(): boolean {
		return this.shouldShowMegaMenuABTestLongTile;
	}

	get shouldShowMegaMenuSmallTile(): boolean {
		return this.shouldShowMegaMenuABTestSmallTile;
	}

	// CHMFP-1871: To be deprecated when dynamic promo tiles go live.
	get promoTileWidth(): number {
		return this.isDesktopNav ? 176 : 268;
	}

	get dynamicPromoTileWidth(): number {
		return this.isDesktopNav ? 176 : 128;
	}

	get currentDepartmentIndex(): number {
		return this._currentDepartmentIndex;
	}

	get currentAisleIndex(): number {
		return this._currentAisleIndex;
	}

	get currentShelveIndex(): number {
		return this._currentShelveIndex;
	}

	set currentDepartmentIndex(index: number) {
		const navItemsLength = this.browseNavItems?.[0].items?.length ?? 0;
		this._currentDepartmentIndex = getNextMenuItemIndex(index, navItemsLength);
	}

	set currentAisleIndex(index: number) {
		const navItemsLength = this.aislesNavItems?.[0].items?.length ?? 0;
		this._currentAisleIndex = getNextMenuItemIndex(index, navItemsLength);
	}

	set currentShelveIndex(index: number) {
		const navItemsLength = this.shelveNavItems?.[0].items?.length ?? 0;
		this._currentShelveIndex = getNextMenuItemIndex(index, navItemsLength);
	}

	setupPromoImpressions(promoTileName: string, itemId: number | undefined, position: number | undefined): void {
		const departmentLabel = this.currentDepartment?.label;
		const aisleLabel = this.currentAisle?.label;
		let creativeName = 'Browse Menu';

		if (departmentLabel) {
			creativeName += ` | ${departmentLabel}`;
			if (aisleLabel) {
				creativeName += ` | ${aisleLabel}`;
			}
		}

		const oddBunchData: PromoTileImpressionArgs = {
			creativeName: creativeName,
			creativeSlot: position ? position.toString() : '',
			promotionId: itemId ? itemId.toString() : '',
			promotionName: promoTileName,
		};
		this.promoImpressionArgs.set(promoTileName, oddBunchData);
	}

	setupFixedPromoImpressions(): void {
		const promotions: PromoBasicArgs[] = [
			{ name: 'The Odd Bunch', itemId: 1, tileIndex: 1 },
			{ name: '3 for 20', itemId: 2, tileIndex: 2 },
		];

		promotions.forEach((promo) => {
			this.setupPromoImpressions(promo.name, promo.itemId, promo.tileIndex);
		});
	}

	setupMegaMenuABTestTile(): void {
		this.flagService
			.getVariationKey(FlagKey.megaMenuProductDiscovery)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((variant) => {
				if (
					variant === MegaMenuProductDiscovery.Variation1 ||
					variant === MegaMenuProductDiscovery.Variation2
				) {
					this.shouldShowMegaMenuABTestSmallTile = true;
					return;
				}

				if (
					variant === MegaMenuProductDiscovery.Variation3 ||
					variant === MegaMenuProductDiscovery.Variation4
				) {
					this.shouldShowMegaMenuABTestLongTile = true;
					return;
				}

				this.shouldShowMegaMenuABTestSmallTile = false;
				this.shouldShowMegaMenuABTestLongTile = false;
			});
	}

	ngOnInit(): void {
		this.resetData();
		this.dynamicNavItem$ = this.navItemsService.dynamicNavItem$;

		this.isNewBrowseMenuLinksEnabled$ = this.featureService.isEnabled(EnabledFeaturesEnum.NewBrowseMenuLinks);
		this.isBrowseMenuAislePromoTileEnabled = this.featureService.isFeatureEnabled(
			EnabledFeaturesEnum.EnableAislePromoTiles
		);

		this.setupFixedPromoImpressions();
		this.currentPromoTiles?.forEach((item, index) => {
			if (item.title) {
				this.setupPromoImpressions(item.title, item.id, index + 1);
			}
		});

		// Setup for A/B test Mega Menu Product discoverability
		this.setupPromoImpressions('T1 EDR', 170, 2);

		this.shouldShowFeatureRecipeStamp$ = this.featureService.isEnabled(
			ContextResponse.EnabledFeaturesEnum.EnableFeatureRecipeStamp
		);

		// Setup for A/B test Mega Menu Tile
		this.setupMegaMenuABTestTile();

		this.flagService
			.getVariationKey(FlagKey.browseMenuHoverExperiment)
			.pipe(map((key) => key === BrowseMenuHoverExperimentVariants.Hover))
			.subscribe((flag) => (this.hoverExperimentActive = flag));

		this.flagService
			.getVariationKey(FlagKey.cartologyMegaMenuFeatureBrandTileExperiment)
			.subscribe((variation) => (this.megaMenuFeatureBrandTileVariationKey = variation));

		this.aislesNavItems$.subscribe((navItems) => {
			this.aislesNavItems = navItems;
		});

		this.shelvesNavItems$.subscribe((navItems) => {
			this.shelveNavItems = navItems;
		});
	}

	ngOnDestroy(): void {
		this.resetData();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	trackByFn: TrackByFunction<NavigationMenuItemWithAnalytics> = (_: any, item: NavigationMenuItemWithAnalytics) =>
		item.label;

	trackByPromoTilesFn: TrackByFunction<BrowseMenuPromoTile> = (_: any, item: BrowseMenuPromoTile) => item.id;

	isURLAbsolute(url: string): boolean {
		return isLinkAbsolute(url);
	}

	// CHMFP-1871: To be deprecated. Temp images for promotion tiles in browse menu experiment.
	imageUrlPromoTiles(tileName: string): string {
		return `/images/global-nav/nav-experiment-v2/${tileName}_${this.isDesktopNav ? 'desktop' : 'mobile'}.png`;
	}

	imageUrlDynamicPromoTile(title: string, imageLink: string): string {
		if (title === this.cartologyFeatureBrandTile) {
			return this.imageUrlMegaMenuFeatureBrandTile(imageLink);
		}
		return imageLink;
	}

	imageUrlMegaMenuFeatureBrandTile(imageLink: string): string {
		const variation = this.megaMenuFeatureBrandTileVariationKey;
		const platform = this.isDesktopNav ? 'desktop' : 'mobile';
		return `${imageLink}/ccfrm3339-${variation}-${platform}.png`;
	}

	showDynamicPromoTile(item: BrowseMenuPromoTile): boolean {
		if (
			item.title === this.cartologyFeatureBrandTile &&
			(this.currentLevel !== CurrentBrowseLevel.Aisles || !this.megaMenuFeatureBrandTileEnabled)
		) {
			return false;
		}
		return true;
	}

	showDepartmentDynamicPromoTile(item: BrowseMenuPromoTile): boolean {
		if (this.isBrowseMenuAislePromoTileEnabled) {
			if (this.currentLevel === CurrentBrowseLevel.Shelves) {
				return true;
			}

			if (
				item.title === this.cartologyFeatureBrandTile &&
				this.currentLevel === CurrentBrowseLevel.Aisles &&
				this.megaMenuFeatureBrandTileEnabled
			) {
				return true;
			}

			return false;
		} else {
			return this.showDynamicPromoTile(item);
		}
	}

	encodeUrlForPromoTile(url: string, promoTileName: string, position: number): string {
		return `${url}?promo_position=BM_${position}&promo_creative=promotional_tiles&promo_item_name=${encodeURIComponent(
			promoTileName
		)}`;
	}

	handleImageError(event: Event): void {
		(event.target as HTMLImageElement).style.display = 'none';
	}

	handleTrackEvent(item: NavigationMenuItem): {
		event: TrackingEvent;
		type: NotificationType;
		name: string | undefined;
	} | null {
		// If it has sub-menu items, don't track any
		if (item.navigationItems?.length) {
			return null;
		}

		return {
			event: this.trackingEvent.NotificationEvent,
			type: this.notificationType.Global,
			name: item.label,
		};
	}

	handleNavItemClicked(): void {
		this.navItemClicked.emit();
	}

	onPromoTileClicked(promoTileName: string, tileId: string, position: number): void {
		const departmentLabel = this.currentDepartment?.label;
		const aisleLabel = this.currentAisle?.label;
		let creativeName = 'Browse Menu';

		if (departmentLabel) {
			creativeName += ` | ${departmentLabel}`;
			if (aisleLabel) {
				creativeName += ` | ${aisleLabel}`;
			}
		}

		this.tealiumUtagService.link(
			{
				...tealiumUtagBrowseMenuPromoTile,
				ecommerce: {
					creative_name: creativeName,
					creative_slot: position ? position.toString() : '',
					promotion_id: tileId,
					promotion_name: promoTileName,
				},
			},
			true
		);
		this.navItemClicked.emit();
	}

	onShopAllClicked(categoryName: string): void {
		if (categoryName === 'Aisle') {
			this.tealiumUtagService.link(
				{
					...tealiumUtagBrowseMenuAisle,
					content_id: this.currentDepartment?.id?.toString(),
					content_name: `Shop all ${this.currentDepartment?.label ?? ''}`,
				},
				true
			);
		} else if (categoryName === 'Shelf') {
			this.tealiumUtagService.link(
				{
					...tealiumUtagBrowseMenuShelf,
					content_id: this.currentAisle?.id?.toString(),
					content_name: `Shop all ${this.currentAisle?.label ?? ''}`,
				},
				true
			);
		}
		this.navItemClicked.emit();
	}

	onShelveClick(navigationItemMap: NavigationItemMap): void {
		this.currentShelveIndex = navigationItemMap.index;
		this.tealiumUtagService.link(
			{
				...tealiumUtagBrowseMenuShelf,
				content_id: navigationItemMap.item.id?.toString() ?? '',
				content_name: navigationItemMap.item.label ?? '',
			},
			true
		);
		this.navItemClicked.emit();
	}

	navigateToBrowseLevel(browserLevel: CurrentBrowseLevel): void {
		this.currentLevel = browserLevel;

		if (browserLevel === CurrentBrowseLevel.Aisles) {
			this.currentShelveIndex = 0;
			this.shelvesNavItems$.next(undefined);
		}
		if (browserLevel === CurrentBrowseLevel.Departments) {
			this.currentAisleIndex = 0;
			this.aislesNavItems$.next(undefined);
		}
	}

	navigateToPriorMenuItem(browserLevel: CurrentBrowseLevel): void {
		const menuIndexProp = MENU_NAV_INDEX_MAPPING[browserLevel];
		this[menuIndexProp] -= 1;
	}

	navigateToNextMenuItem(browserLevel: CurrentBrowseLevel): void {
		const menuIndexProp = MENU_NAV_INDEX_MAPPING[browserLevel];
		this[menuIndexProp] += 1;
	}

	navigateToFirstMenuItem(browserLevel: CurrentBrowseLevel): void {
		const menuIndexProp = MENU_NAV_INDEX_MAPPING[browserLevel];
		this[menuIndexProp] = 0;
	}

	navigateToLastMenuItem(browserLevel: CurrentBrowseLevel): void {
		const menuIndexProp = MENU_NAV_INDEX_MAPPING[browserLevel];
		this[menuIndexProp] = -1;
	}

	public onAisleClick(navigationItemMap: NavigationItemMap): void {
		this.onAisleSelected(navigationItemMap);
		if (this.hoverExperimentActive && this.isDesktopNav && this.currentAisle) {
			this.tealiumUtagService.link(
				{
					...tealiumUtagBrowseMenuAisle,
					content_id: this.currentAisle.id?.toString() ?? '',
					content_name: this.currentAisle.label ?? '',
				},
				true
			);
		}
	}

	public onAisleHover(navigationItemMap: NavigationItemMap): void {
		this.onAisleSelected(navigationItemMap);
	}

	public onClose(): void {
		this.closeButtonClicked.emit();
	}

	public onDepartmentClick(navigationItemMap: NavigationItemMap): void {
		this.onDepartmentSelected(navigationItemMap);
		if (this.hoverExperimentActive && this.isDesktopNav && this.currentDepartment) {
			this.tealiumUtagService.link(
				{
					...tealiumUtagBrowseMenuDepartment,
					content_id: this.currentDepartment.id?.toString() ?? '',
					content_name: this.currentDepartment.label ?? '',
				},
				true
			);
		}
	}

	public onDepartmentHover(navigationItemMap: NavigationItemMap): void {
		this.onDepartmentSelected(navigationItemMap);
	}

	public trackingData(name: string, value: string): TrackingData {
		return getTrackingData(name, NotificationType.Global, value);
	}

	public handleBackButtonClick(): void {
		this.resetData();
		this.backButtonClicked.emit();
	}

	public handleBackToPreviousMenuClick(): void {
		if (this.currentLevel === CurrentBrowseLevel.Aisles) {
			this.resetData();
		} else if (this.currentLevel === CurrentBrowseLevel.Shelves) {
			this.currentAisleIndex = 0;
			this.currentShelveIndex = 0;
			this.shelvesNavItems$.next(undefined);
			this.currentLevel = CurrentBrowseLevel.Aisles;
		}
		this.cdr.markForCheck();
	}

	public resetData(): void {
		this.currentLevel = CurrentBrowseLevel.Departments;
		this.currentDepartment = undefined;
		this.currentAisle = undefined;
		this.currentPromoTiles = this.browseNavItems ? this.browseNavItems[0]?.promoTiles : undefined;
		this.currentDepartmentIndex = 0;
		this.currentAisleIndex = 0;
		this.currentShelveIndex = 0;
		this.aislesNavItems$.next(undefined);
		this.shelvesNavItems$.next(undefined);
	}

	private onDepartmentSelected(navigationItemMap: NavigationItemMap): void {
		this.currentDepartmentIndex = navigationItemMap.index;
		this.currentAisleIndex = 0;
		this.currentAisle = undefined;
		this.currentLevel = CurrentBrowseLevel.Aisles;
		this.currentDepartment = navigationItemMap.item;
		this.currentPromoTiles = this.currentDepartment?.promoTiles;
		this.currentShelveIndex = 0;
		this.shelvesNavItems$.next(undefined);

		this.currentPromoTiles?.forEach((item, index) => {
			if (item.title) {
				this.setupPromoImpressions(item.title, item.id, index + 1);
			}
		});

		if (this.currentDepartment) {
			const url = this.currentDepartment?.url ?? '';
			const departmentName = this.currentDepartment.label ?? '';
			this.aislesNavItems$.next([
				{
					items: generateAislesNavItems(this.currentDepartment.dasFacets ?? [], url, departmentName),
					label: departmentName,
					url: url,
				},
			]);
		}
	}

	private onAisleSelected(navigationItemMap: NavigationItemMap): void {
		this.currentAisle = navigationItemMap.item;

		if (this.currentAisle && this.isBrowseMenuAislePromoTileEnabled) {
			const promoTiles =
				browseNavAisleItemMockWithPromoTiles?.navigationItems?.[0]?.items?.[0]?.dasFacets?.[0]?.promoTiles ??
				[];

			const adjustedPromoTiles = promoTiles.map((tile) => ({
				...tile,
				startDate: tile.startDate,
				endDate: tile.endDate,
			}));
			this.currentPromoTiles = [...(this.currentDepartment?.promoTiles || []), ...adjustedPromoTiles];
		} else {
			this.currentPromoTiles = this.currentDepartment?.promoTiles || [];
		}

		if (this.currentAisle && this.currentAisle.shelfResponses) {
			this.currentAisleIndex = navigationItemMap.index;
			this.currentShelveIndex = 0;
			this.currentLevel = CurrentBrowseLevel.Shelves;

			const url = this.currentAisle.url ?? '';
			const aisleName = this.currentAisle.label ?? '';
			const items = generateShelvesNavItems(this.currentAisle.shelfResponses, url, aisleName);

			this.shelvesNavItems$.next([
				{
					items: items,
					label: aisleName,
					url: url,
				},
			]);
			this.cdr.markForCheck();
		}
	}
}
