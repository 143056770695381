<div *ngIf="(isVisible$ | async)">
	<div class="browse-product-stamp">
		<a
			[href]="linkHref"
			class="product-entry"
		>
			<div class="browse-product-stamp-mainImage">
				<figure>
					<img
						[width]="width"
						deferLoad
						class="product-image"
						[src]="src"
						[alt]="product?.name"
					/>
				</figure>
			</div>
			<div class="product-details">
				<p class="heading--3 product-price">
					{{ product?.price?.salePrice | currency }}
				</p>
				<p
					class="priceMeta"
					id="product-{{ product?.sku }}-volumeSize"
					*ngIf="volumeSize || packageType || cupPrice"
					[attr.aria-label]="ariaLabels | join: ' ' | srPunctuation"
				>
					<span class="size" *ngIf="volumeSize || packageType">
						<ng-container *ngIf="packageType">{{ packageType }}&nbsp;</ng-container>
						<ng-container *ngIf="volumeSize && !isKgItem">{{ volumeSize }}&nbsp; </ng-container>
					</span>
					<span class="cupPrice" *ngIf="cupPrice">{{ cupPrice }}</span>
				</p>
				<h3
					id="product-{{ product?.sku }}-title"
					[attr.aria-label]="product?.name | titlecase | capitalizeSpecificWords | srPunctuation"
				>
					{{ productTitle | capitalizeSpecificWords }}
				</h3>
			</div>
			<footer>
				<button
					class="browse-product-stamp-btn-add"
					attr.data-cy="addToTrolleyBtn"
					cdxButton
					buttonState="default"
					[disabled]="!productService.productIsInStock"
					fullWidth="true"
					aria-live="polite"
					fillStyle="outline"
				>
					Add to trolley
				</button>
			</footer>
		</a>
	</div>
</div>
