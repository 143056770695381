import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CustomWindow, InjectScriptService, WINDOW } from '@woolworthsnz/styleguide';

@Component({
	selector: 'global-nav-socials',
	template: `
		<a href="https://www.facebook.com/woolworthsnewzealand" title="Facebook Countdown" target="_new">
			<img
				alt="Facebook icon"
				height="30"
				src="/images/global-nav/footer/icon-facebook-logo-desktop.svg"
				width="30"
			/>
		</a>
		<a href="https://www.pinterest.nz/woolworths_nz" title="Pinterest Countdown" target="_new">
			<img
				alt="Pinterest icon"
				height="30"
				src="/images/global-nav/footer/icon-pinterest-logo-desktop.svg"
				width="30"
			/>
		</a>
		<a href="https://www.linkedin.com/company/woolworths-newzealand" title="LinkedIn Countdown" target="_new">
			<img
				alt="LinkedIn icon"
				height="30"
				src="/images/global-nav/footer/icon-linkedin-logo-desktop.svg"
				width="30"
			/>
		</a>
		<a href="https://www.youtube.com/@woolworths_nz" title="YouTube Countdown" target="_new">
			<img
				alt="Youtube icon"
				height="30"
				src="/images/global-nav/footer/icon-youtube-logo-desktop.svg"
				width="30"
			/>
		</a>
		<a href="https://www.instagram.com/woolworths_nz/" title="Instagram Countdown" target="_new">
			<img
				alt="Instagram icon"
				height="30"
				src="/images/global-nav/footer/icon-instagram-logo-desktop.svg"
				width="30"
			/>
		</a>
		<a id="refugedesktop" class="refugebutton" href="/" arial-label="shielded">
			<img alt="shielded" height="30" width="30" src="/images/global-nav/footer/icon-shielded-logo.png" />
		</a>
	`,
	styleUrls: ['./socials.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class SocialsComponent implements OnInit {
	constructor(
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platformId: Object,
		private injectScriptService: InjectScriptService
	) {}

	ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.injectScriptService.injectScript(this.window.document, 'assets/embed.js', () => {
			this.initShieldedSite();
		});
	}

	initShieldedSite(): void {
		if (this.window && this.window.ds07o6pcmkorn) {
			const frameName = new this.window.ds07o6pcmkorn({
				openElementId: '#refugedesktop',
			});
			frameName.init();
		}
	}
}
